const theme = {
  space: {
    xs: 4,
    s: 8,
    m: 16,
    l: 32,
    xl: 64,
    xxl: 128,
  },
  text: {
    default: {
      color: "text",
      fontSize: "m",
    },
  },
  fontSizes: {
    xs: "1.2rem",
    s: "1.4rem",
    m: "1.6rem",
    l: "1.8rem",
    xl: "2.0rem",
    xxl: "2.4rem",
    xxxl: "3.0rem",
  },
  styles: {
    p: {
      fontSize: "m",
    },
    span: {
      fontSize: "m",
    },
    a: {
      fontSize: "m",
      color: "secondary",
    },
    h1: {
      fontSize: "xxxl",
    },
    h2: {
      fontSize: "xxl",
    },
    h3: {
      fontSize: "xl",
    },
    h4: {
      fontSize: "l",
    },
    h5: {
      fontSize: "m",
    },
    h6: {
      fontSize: "s",
    },
    hr: {
      width: "80%",
      borderColor: "text",
      my: "m",
      borderStyle: "inset",
    },
  },
  links: {
    siteName: {
      color: "text",
      textDecoration: "none",
      fontWeight: "bold",
      fontSize: "xxxl",
    },
    title: {
      color: "link",
      textDecoration: "none",
      fontWeight: "bold",
    },
    nav: {
      fontWeight: "bold",
      color: "text",
      textDecoration: "none",
      mr: 4,
    },
    unstyled: {
      textDecoration: "none",
      fontWeight: "unset",
      color: "unset",
    },
  },
  headings: {
    articleHeading: {
      color: "link",
      fontSize: 26,
    },
  },
  grids: {
    articleGrid: {
      gridColumns: [1, 1, 2],
      gap: 64,
    },
  },
  cards: {
    articleCard: {
      // bg: "highlight",
      py: "m",
      px: "s",
    },
  },
  images: {
    rounded: {
      borderRadius: "10px",
    },
    avatar: {
      borderRadius: 99999,
    },
    hero: {
      width: "100%",
    },
  },
  boxes: {
    verticalSeparated: {
      py: "m",
      borderColor: "gray",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderTopWidth: "2px",
      borderBottomWidth: "2px",
    },
  },
  buttons: {
    form: {
      p: "m",
      mb: "m",
    },
  },
  forms: {
    label: {
      fontSize: "m",
      fontWeight: "bold",
    },
    input: {
      borderColor: "gray",
      p: "s",
      mb: "m",
      "&:focus": {
        borderColor: "primary",
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    select: {
      borderColor: "gray",
      p: "s",
      mb: "m",
      "&:focus": {
        borderColor: "primary",
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      mb: "m",
      p: "s",
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    slider: {
      mb: "m",
      p: "s",
      bg: "muted",
    },
  },
}

export default theme
